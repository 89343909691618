
import React, { useEffect } from 'react'
import { HiOutlineClipboard, HiOutlineDownload, HiOutlinePencilAlt } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import { TicketSection } from './TicketContent'
import { useState } from 'react'
import { Button, Card, Input, Tag, Tooltip } from 'components/ui'
import FileItemCard from 'components/viewComponents/FileItemCard'
import { apiGetQuestionGroupById } from 'services/QuestionGroupService'
import { DATE, FILE, TITLE } from 'constants/app.constant'
import { dateFormat } from 'utils/storeHube/helpersDate'
import ReactHtmlParser from 'react-html-parser'
import TicketAnswerNew from './TicketAnswerNew'
import { apiTicketReplies } from 'services/TicketsService'
import { statusResponse } from 'constants/apiResponse.constant'
import { setSelectedTicket } from '../store/dataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { showMessageError } from 'utils/storeHube/helpers'


const TicketAnswers = ({ ticket, ticket_answer, question_group, ticket_answer_attempt, distance }) => {


    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [questionGroup, setQuestionGroup] = useState()
    const [answers, setAnswers] = useState([])
    const [distanceAndDecice, setDistanceAndDevice] = useState()
    const user = useSelector((state) => state.auth.user);
    const { props } = ticket
    const { operator } = props


    const [visible, setVisible] = useState(false)
    const [idSelected, setIdSelected] = useState(null)
    const [loading, setLoadind] = useState(false)


    const handleShow = (id) => {
        if (idSelected === id) {
            setVisible(!visible) // Toggle si es el mismo id
        } else {
            setVisible(true) // Abre el nuevo
            setIdSelected(id)
        }
    }
    const [commentData, setCommentData] = useState('');

    const handleComment = (e, replyName) => {
        setCommentData({
            text: e.target.value,
            replyTo: replyName
        });
    }

    const addComment = (ticket, newComment) => {

        let props = ticket.props
        const comments = [newComment, ...props.ticket_reply]
        const ticketUpdateReply = {
            ...ticket, props: {
                ...props, ticket_reply: comments, ticket_attachment: [...props.ticket_attachment, ...newComment?.ticket_reply_attachment]
            }
        }
        dispatch(setSelectedTicket(ticketUpdateReply))
    }
    const formatComment = (commentData) => {
        return `<p style="font-weight: bold; color: #4B5563">${commentData.replyTo}</p><p>${commentData.text}</p>`;
    };


    const onSubmit = async (resetForm) => {
        // Validar que commentData tenga contenido
        if (!commentData || !commentData.text?.trim()) {
            showMessageError("El mensaje no puede estar vacío");
            return;
        }

        const replyFormData = new FormData();
        replyFormData.append("message", formatComment(commentData));

        setLoadind(true);
        try {
            const { data, status } = await apiTicketReplies(distanceAndDecice.ticket_id, replyFormData);
            if (status === statusResponse.CREATE) {
                let reply = data.data;
                reply.created_at = dateFormat(reply.created_at, false);
                addComment(ticket, reply);
                setLoadind(false);
                setCommentData('');
                setIdSelected("")
            }

        } catch (error) {
            showMessageError(error.response.data.message);
            setLoadind(false);
            resetForm();
        }
    }

    const isDateAfter = (dateToCompare) => {
        const baseDate = new Date('2025-02-05T23:59:56.000000Z');
        const compareDate = new Date(dateToCompare);
        return compareDate > baseDate;
    }

    // se busca el question_group_id en ticket_answer para ver que respuesta debemos mostrar, si la de apertura o cierre


    useEffect(() => {
        if (ticket_answer_attempt) {
           /*  setDistanceAndDevice(ticket_answer_attempt.find((item) => item.question_group_id === question_group.id)) */
            setDistanceAndDevice(ticket_answer_attempt.find((item) => item.question_group_id === ticket_answer[0]?.question_group_id))

        }
    }, [ticket_answer_attempt])


    useEffect(() => {
        if (question_group) {
            if (question_group?.id !== ticket_answer[0]?.question_group_id) {
                apiGetQuestionGroupById(ticket_answer[0]?.question_group_id).then((res) => {
                    setQuestionGroup(res.data.data)
                })
            } else {
                setQuestionGroup(question_group)
            }
        }
    }, [question_group])


    const getIncidents = (q, a) => {
        let question = q
        if (q?.question_option?.length) {
            question = q.question_option.find(qo => qo.id === a.question_option_id)
        }
        return question.incidents
    }

    const getNameAnswerArray = (inputArray) => {

        return inputArray.map(obj => {
            const answer = obj.answer;
            const questionType = obj.question.question_type_id;
            if (questionType === FILE) {
                return {
                    name: obj.answer.question,
                    id: obj.question.id,
                    answer: {
                        id: answer.id,
                        size: parseInt(answer.size),
                        original_name: answer.original_name,
                        extension: answer.extension,
                        file: answer.file,
                        rotate: answer.rotate
                    },
                    question_type: questionType,
                    priority: obj.question.priority,
                };
            } else if (questionType === TITLE) {
                return {
                    name: obj.answer.question ? obj.answer.question : obj.answer.name,
                    id: obj.question.id,
                    answer: {
                    },
                    question_type: questionType,
                    priority: obj.question.priority,
                };

            } else {
                return {
                    name: obj.answer.question ? obj.answer.question : obj.answer.name,
                    id: obj.question.id,
                    answer: {
                        answers: answer.value,
                        incidents: getIncidents(obj.question, obj.answer), 
                        id: answer.id,
                    },
                    question_type: questionType,
                    priority: obj.question.priority,
                };
            }
        })
            .sort((a, b) => a.priority - b.priority);
    };

    function groupByName(arr) {

        const result = {};
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (result[item.id]) {
                result[item.id].answers.push(item.answer);
            } else {
                result[item.id] = { name: item.name, answers: [item.answer], question_type: item.question_type, priority: item.priority };
            }
        }

        const sortedValues = Object.values(result).sort((a, b) => a.priority - b.priority);
        return sortedValues;
    }

    const matchAnswersToQuestions = (answers, questions) => {
        let matchedAnswers = [];
        for (let i = 0; i < answers.length; i++) {
            for (let j = 0; j < questions.length; j++) {
                if (answers[i].question_id === questions[j].id) {
                    // Validar que si el question_type es 8 y visible es false, no se incluya
                    if (questions[j].question_type_id === 8 && answers[i].visible === false) {
                        continue; // Salta este par de respuesta y pregunta
                    }
                    matchedAnswers.push({ answer: answers[i], question: questions[j] });
                    break;
                }
            }
        }
        return getNameAnswerArray(matchedAnswers);
    }

    useEffect(() => {
        questionGroup && setAnswers(groupByName(matchAnswersToQuestions(ticket_answer, questionGroup?.questions)))
    }, [questionGroup])

    const getNewNameAnswerArray = (inputArray) => {
        return inputArray
            .map(item => {
                const questionType = item.question_type_id;

                if (questionType === FILE) {
                    return {
                        name: item.question,
                        id: item.question_id,
                        answer: {
                            id: item.id,
                            size: parseInt(item.size || '0'),
                            original_name: item.original_name,
                            extension: item.extension,
                            file: item.file,
                            rotate: item.rotate,
                            penality: item.penality
                        },
                        question_type: item.question_type_id,
                        priority: item.priority,

                    };
                } else if (questionType === TITLE) {
                    return {
                        name: item.question || item.value,
                        id: item.question_id,
                        answer: {},
                        question_type: item.question_type_id,
                        priority: item.priority
                    };
                } else {
                    return {
                        name: item.question,
                        id: item.question_id,
                        answer: {
                            answers: item.value,
                            incidents: item.incidents,
                            penality: item.penality,
                            id: item.id,
                        },
                        question_type: item.question_type_id,
                        priority: item.priority,

                    };
                }
            })
            .filter(item => item.name !== undefined)
            .sort((a, b) => a.priority - b.priority);
    };


    return (
        <TicketSection /* title={t("answers")} icon={<HiOutlineClipboard />} */>
            {distanceAndDecice && isDateAfter(distanceAndDecice.created_at) ?
                <TicketAnswerNew ticket={ticket} answers={groupByName(getNewNameAnswerArray(ticket_answer))} distanceAndDecice={distanceAndDecice} questionGroup={questionGroup} distance={distance} ticketId={distanceAndDecice.ticket_id} /> :
                <div className=" flex flex-col grid-cols-1 md:grid-cols-3 gap-4 mt-2">
                    <h5>{questionGroup?.name}</h5>
                    {distance && distanceAndDecice && <span className=' capitalize '>{t("device")}: {distanceAndDecice.device}</span>}
                    {distance && distanceAndDecice && <span>{t("distance")}: {distanceAndDecice.distance}</span>}

                    {
                        answers.map((answer, i) => (
                            <div key={i}>
                                <div className='flex items-center mb-3 gap-2'>
                                    <p className={`text-gray-700 dark:text-gray-200 font-semibold ${answer.question_type === 8 ? 'text-base' : 'text-xs'}`}>
                                        {answer.name}
                                    </p>

                                </div>
                                {answer.answers.length > 0 && answer.answers.map((r, i) => (
                                    <div className='flex flex-col mb-2  gap-2' key={i}>
                                        <div className='flex  items-center gap-2'>
                                            <span>{answer.question_type === DATE ? dateFormat(r.answers, 'DD-MM-YYYY') : ReactHtmlParser(r.answers)}</span>

                                            {r.incidents &&
                                                <>
                                                    <Tag className="bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 border-0 rounded text-xs p-1">
                                                        {t('incidence')}
                                                    </Tag>
                                                </>
                                            }
                                            {r.penality &&
                                                <Tag className="bg-red-100 text-red-600 dark:bg-red-500/20 dark:text-red-100 border-0 rounded text-xs p-1">
                                                    {t('penalizable')}
                                                </Tag>
                                            }

                                            {r.incidents && operator.id === user.id &&
                                                <div className='flex flex-col items-center gap-2'>
                                                    <HiOutlinePencilAlt size={20} onClick={() => handleShow(r.id)} />
                                                </div>
                                            }

                                        </div>
                                        {visible && idSelected === r.id &&
                                            <div className='w-[80%] flex flex-col items-end'>
                                                <Input
                                                    value={commentData.text}
                                                    onChange={(e) => handleComment(e, answer.name)}
                                                />
                                                <Button
                                                    size="xs"
                                                    variant="outline"
                                                    onClick={onSubmit}
                                                    loading={loading}
                                                    className="mt-2 w-36"
                                                >
                                                    {t("send_comment")}
                                                </Button>
                                            </div>
                                        }
                                    </div>
                                ))}

                                {answer.question_type === FILE &&
                                    <div className='flex flex-wrap gap-2'>
                                        {answer?.answers?.length > 0 &&
                                            answer?.answers.map(answer => (
                                                <Card
                                                    className="w-[165px]"
                                                    bodyClass="px-2 pt-2 pb-1"
                                                    key={answer.original_name}
                                                >
                                                    <FileItemCard className="w-[50px]" file={answer} />
                                                    <div className="mt-1 flex justify-between items-center">
                                                        <span className="text-xs">{(parseInt(answer.size) / 1024).toFixed(2)} KB</span>
                                                        <div className="flex items-center">
                                                            <Tooltip title={t('download')}>
                                                                <a href={answer.file} download target="_blank">
                                                                    <Button className="mr-1 rtl:ml-1" variant="plain" size="xs" icon={<HiOutlineDownload />} />
                                                                </a>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </Card>
                                            ))
                                        }
                                    </div>
                                }

                            </div>
                        ))

                    }
                </div>

            }

        </TicketSection>
    )
}

export default TicketAnswers