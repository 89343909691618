import React, { memo, useMemo, lazy, Suspense } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
	LAYOUT_TYPE_MODERN,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { useEffect } from 'react'
import { getTicketGlobalData } from 'views/tickets/store/globalSlice'
import TicketDialog from 'views/tickets/ticketList/TicketDialog'
import TicketNotification from 'services/Pusher/TicketNotification'
import { useQuery } from '@apollo/client'
import { PARAMETERS_TENANT } from 'services/graphql/api/Parameters'
import { setParameters, setPublicParameters } from 'store/auth/userSlice'
import moment from 'moment'
import 'moment-timezone';
import { apiGetPublicParameters } from 'services/ParametersService'

const layouts = {
	[LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
}

const Layout = () => {

	const layoutType = useSelector((state) => state.theme.layout.type)

	const { authenticated } = useAuth()

	const { session, user } = useSelector((state) => state.auth)
	const { signedIn } = session
	const { timezone } = user


	useDirection()

	useLocale()

	const dispatch = useDispatch()

	useEffect(() => {
		if (authenticated) setTimeout(() => fetchData(), 500)
	}, [authenticated])

	useEffect(() => {
		if (timezone?.code) {
			moment.tz.setDefault(timezone?.code);
		}
	}, [timezone])

	useEffect(() => {
		fetchPublicParameters()
	}, [])

	const fetchData = async () => {
		dispatch(getTicketGlobalData())
	}

	const fetchPublicParameters = async () => {
		if (!signedIn) {
			const { data } = await apiGetPublicParameters()
			dispatch(setPublicParameters(data))
		}
	}

	const response = useQuery(PARAMETERS_TENANT, { skip: !signedIn })
	if (!response.loading && response.data) dispatch(setParameters(response.data))



	const AppLayout = useMemo(() => {
		if (authenticated) {
			return layouts[layoutType]
		}
		return lazy(() => import('./AuthLayout'))
	}, [layoutType, authenticated])

	return (
		<Suspense
			fallback={
				<div className="flex flex-auto flex-col h-[100vh]">
					<Loading loading={true} />
				</div>
			}
		>
			<AppLayout />
			<TicketDialog />
			<TicketNotification />
		</Suspense>
	)
}

export default memo(Layout)