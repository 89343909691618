import { Provider } from 'react-redux'

import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import { ApolloProvider } from '@apollo/client'
import { clientGraphql } from 'services/graphql/graphqlService'
import PusherProvider from 'services/Pusher'
import ErrorBoundary from 'ErrorHandlerPage'
import "./app.css"

const environment = process.env.NODE_ENV


/**
 * Set enableMock(Default false) to true at configs/app.config.js 
 * If you wish to enable mock api
 */
/* if (environment !== 'production' && appConfig.enableMock) {
	mockServer({ environment })
}
 */
function App() {
	return (
			<ErrorBoundary>
				<ApolloProvider client={clientGraphql}>
					<Provider store={store}>
						<PersistGate loading={null} persistor={persistor}>
							<BrowserRouter history={history}>
								<Theme>
									<PusherProvider>
										<Layout />
									</PusherProvider>
								</Theme>
							</BrowserRouter >
						</PersistGate>
					</Provider>
				</ApolloProvider>
			</ErrorBoundary>
	)
}

export default App
