import React from 'react'
import { HiOutlineChatAlt } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import { TicketSection } from './TicketContent'
import SkeletonTicketComments from '../ticketList/SkeletonTicketComments'
import TicketTimeline from './TicketTimeline'

const TicketActivity = ({ loadingSelectedTicket, ticket_reply, ticket_log, ticket_answer }) => {

  const { t } = useTranslation()

  return (
    <TicketSection /* title={t('activity')} icon={<HiOutlineChatAlt />} */>

      {loadingSelectedTicket
        ? <div className="mt-2 w-full">
          <SkeletonTicketComments />
        </div>
        : <div>
          <TicketTimeline props={{ ticket_reply, ticket_log, ticket_answer }} />
        </div>
      }
    </TicketSection>
  )
}

export default TicketActivity