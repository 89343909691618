import ApiService from './ApiService'

export async function apiSignIn (data) {
    return ApiService.fetchData({
        url: '/login',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/account/logout',
        method: 'get',
        data
    })
}

export async function apiMe(){
    return ApiService.fetchData({
        url: '/account/me',
        method: 'get',
        //headers: { "Authorization": `Bearer ${access_token}` },
    })
}
export async function apiMeProfile(){
    return ApiService.fetchData({
        url: '/account/me',
        method: 'get',
    })
}

export async function apiPostMeProfile (data) {
    return ApiService.fetchData({
        url: '/account/me',
        method: 'post',
       headers: { "Content-Type": "multipart/form-data" },
         data
    })
}


export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/recover',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/recover-password',
        method: 'post',
        data
    })
}
export async function apiImpersonateUser(id) {
    return ApiService.fetchData({
        url: `/users/${id}/impersonate`,
        method: 'post',
       
    })
}
export async function apiMeImpersonate(access_token){
    console.log(access_token)
    return ApiService.fetchData({
        url: '/account/me',
        method: 'get',
      /*   headers: { "Authorization": `Bearer ${access_token}` }, */
    })
}


export async function apiMeChangePassword(data){
    return ApiService.fetchData({
        url: '/account/change_password',
        method: 'post',
        data
      /*   headers: { "Authorization": `Bearer ${access_token}` }, */
    })
}

export async function apiValidateCode(data) {
    return ApiService.fetchData({
        url: '/validate-code',
        method: 'post',
        data,
    })
}

export async function apiSignInOAuth (data) {
    return ApiService.fetchData({
        url: '/login_oauth',
        method: 'post',
        data
    })
}


















//OLDS
export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/register',
        method: 'post',
        data
    })
}