import ApiService from "./ApiService";

export async function apiGetParameterOpenHours () {
    return ApiService.fetchData({
        url: `/parameters/openhours`,
        method: 'get',
    })
}

export async function apiPostParameterOpenHours (data) {
    return ApiService.fetchData({
        url: `/parameters/openhours`,
        method: 'post',
        data
    })
}


export async function apiGetParametersSettings () {
    return ApiService.fetchData({
        url: `/parameters`,
        method: 'get',
    })
}
export async function apiPostParameterSettings (data) {
    return ApiService.fetchData({
        url: `/parameters`,
        method: 'post',
        data
    })
}

export async function apiGetCurrencies() {
    return ApiService.fetchData({
        url: `/currencies`,
        method: 'get',
    })
}

export async function apiGetPublicParameters () {
    return ApiService.fetchData({
        url: `/tenant_parameters`,
        method: 'get',
    })
}