import { Button, FormContainer, FormItem } from "components/ui"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import usePermissions from "utils/hooks/usePermissions"
import { scroller, showMessageError } from "utils/storeHube/helpers"
import { dateFormat } from "utils/storeHube/helpersDate"
import { setSelectedTicket } from "../store/dataSlice"
import * as Yup from 'yup'
import { apiTicketReplies } from "services/TicketsService"
import { statusResponse } from "constants/apiResponse.constant"
import { Field, Form, Formik } from "formik"
import { Loading, RichTextEditor } from "components/shared"
import { getDescriptionImages } from "../ticketList/ImageResizer"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { HiOutlinePaperClip } from 'react-icons/hi'
import { Avatar, Upload } from "components/ui"
import { VscFile } from "react-icons/vsc"

const TicketAddComment = ({ ticket, disabled }) => {


    const { id } = ticket

    const { t } = useTranslation()

    const dispatch = useDispatch()

    const { checkPermission } = usePermissions()
    const tickets_can_reply = checkPermission('tickets.can_reply')
    const tickets_can_attachment = checkPermission('tickets.can_attachment')

    const { loadingSelectedTicket } = useSelector(state => state.ticketList.data)


    const [previewFile, setPreviewFile] = useState(null)
    const [file, setFile] = useState(false)
    const onFileUpload = f => {
        const file = f[f.length - 1]
        setPreviewFile(URL.createObjectURL(file))
        setFile(file)
    }

    const resetFiles = () => {
        setPreviewFile(null)
        setFile(false)
    }


    const addComment = (ticket, newComment) => {
        let props = ticket.props
        const comments = [newComment, ...props.ticket_reply]
        const ticketUpdateReply = {
            ...ticket, props: {
                ...props, ticket_reply: comments, ticket_attachment: [...props.ticket_attachment, ...newComment?.ticket_reply_attachment]
            }
        }
        dispatch(setSelectedTicket(ticketUpdateReply))
    }


    const onSubmit = async (formValue, setSubmitting, resetForm) => {

        setSubmitting(true)
        try {

            const { files, elements } = await getDescriptionImages(formValue?.message)

            const replyFormData = new FormData();
            replyFormData.append("message", elements)
            files.map(file => {
                tickets_can_attachment && replyFormData.append("files[]", file);
            })
            file && replyFormData.append("files[]", file)

            const { data, status } = await apiTicketReplies(id, replyFormData)
            if (status === statusResponse.CREATE) {
                let reply = data.data
                reply.created_at = dateFormat(reply.created_at, false)
                addComment(ticket, reply);
                scroller('replies')
                setSubmitting(false)
                resetFiles()
                resetForm()
            }

        } catch (error) {
            showMessageError(error.response.data.message)
            setSubmitting(false)
            resetForm()

        }
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ header: [2, 3, 4, false] }],
                    [{ 'size': ['small', false, 'large'] }], 
                    ["bold", "italic", "underline"],
                    [{ color: [] }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
                    [{ 'align': [] }],
                  /*   ["link" ], */
                    ["clean"],
                ],
            },
            clipboard: {
                matchVisual: true,
            },
        }),
    );


    if (tickets_can_reply) {
        return (
            <div className="p-1">
                <Loading
                    spinnerClass={'hidden'}
                    loading={loadingSelectedTicket}
                >

                    <Formik
                        initialValues={{ message: '' }}
                        validationSchema={
                            Yup.object().shape({
                                message: Yup.string().required('Agregue un comentario'),
                            })
                        }
                        onSubmit={(values, { resetForm, setSubmitting, }) => {
                            onSubmit(values, setSubmitting, resetForm)
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({ values, touched, errors, resetForm, isSubmitting }) => (
                            <Form>
                                <FormContainer>

                                    <FormItem
                                        className="w-full mr-2"
                                        invalid={errors.message}
                                        errorMessage={errors.message}
                                    >
                                        <Field
                                            name="message"
                                            placeholder={t('writeComment')}
                                        >
                                            {({ field, form }) => (
                                                <RichTextEditor
                                                    value={field.value}
                                                    modules={modules}
                                                    onChange={val => {
                                                        form.setFieldValue(field.name, val === '<p><br></p>' ? "" : val)
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </FormItem>

                                    <div className="flex justify-between items-center">
                                    {tickets_can_attachment &&
                                    <div className="mb-2 flex items-center">

                                        <Upload
                                            className="cursor-pointer flex "
                                            onChange={onFileUpload}
                                            showList={false}
                                        //uploadLiit={1}
                                        >
                                            {
                                                file && !file.type.includes('image')
                                                    ? <VscFile size={20} className="mr-2 " />
                                                    : <Avatar size={20} src={previewFile} className='mr-2 ' icon={<HiOutlinePaperClip />} />
                                            }
                                        <span>{t("can_attachment")} </span>
                                        </Upload>
                                    </div>
                                    }

                                    <FormItem>
                                        <Button
                                            size="xs"
                                            variant="outline"
                                            type="submit"
                                            disabled={!values?.message?.length  || disabled}
                                            loading={isSubmitting}
                                        >
                                            {t("send_comment")}
                                        </Button>
                                    </FormItem>
                                    </div>

                                  
                                </FormContainer>
                            </Form>
                        )}
                    </Formik>
                </Loading>
            </div>
        )

    }

}
export default TicketAddComment