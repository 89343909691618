import React, { useState } from 'react'
import { HiOutlineCloudUpload, HiOutlineDownload, HiOutlineLockClosed, HiOutlinePencilAlt } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'
import { Button, Card, Input, Tag, Tooltip } from 'components/ui'
import FileItemCard from 'components/viewComponents/FileItemCard'
import { DATE, FILE, TITLE } from 'constants/app.constant'
import { dateFormat } from 'utils/storeHube/helpersDate'
import ReactHtmlParser from 'react-html-parser'
import { apiTicketReplies } from 'services/TicketsService'
import { statusResponse } from 'constants/apiResponse.constant'
import { setSelectedTicket } from '../store/dataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { showMessageError } from 'utils/storeHube/helpers'

const TicketAnswerNew = ({ ticket, answers, distanceAndDecice, questionGroup, distance, ticketId }) => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const user = useSelector((state) => state.auth.user);
    const {props} = ticket
    const {operator} = props
 
    const [visible, setVisible] = useState(false)
    const [idSelected, setIdSelected] = useState(null)
    const [loading, setLoadind] = useState(false)


    const handleShow = (id) => {
        if (idSelected === id) {
            setVisible(!visible) // Toggle si es el mismo id
        } else {
            setVisible(true) // Abre el nuevo
            setIdSelected(id)
        }
    }
    const [commentData, setCommentData] = useState('');

    const handleComment = (e, replyName) => {
        setCommentData({
            text: e.target.value,
            replyTo: replyName
        });
    }

    const addComment = (ticket, newComment) => {

        let props = ticket.props
        const comments = [newComment, ...props.ticket_reply]
        const ticketUpdateReply = {
            ...ticket, props: {
                ...props, ticket_reply: comments, ticket_attachment: [...props.ticket_attachment, ...newComment?.ticket_reply_attachment]
            }
        }
        dispatch(setSelectedTicket(ticketUpdateReply))
    }
    const formatComment = (commentData) => {
        return `<p style="font-weight: bold; color: #4B5563">${commentData.replyTo}</p><p>${commentData.text}</p>`;
    };


    const onSubmit = async (resetForm) => {
        // Validar que commentData tenga contenido
        if (!commentData || !commentData.text?.trim()) {
            showMessageError("El mensaje no puede estar vacío");
            return;
        }

        const replyFormData = new FormData();
        replyFormData.append("message", formatComment(commentData));

        setLoadind(true);
        try {
            const { data, status } = await apiTicketReplies(ticketId, replyFormData);
            if (status === statusResponse.CREATE) {
                let reply = data.data;
                reply.created_at = dateFormat(reply.created_at, false);
                addComment(ticket, reply);
                setLoadind(false);
                setCommentData('');
                setIdSelected("")
            }

        } catch (error) {
            showMessageError(error.response.data.message);
            setLoadind(false);
            resetForm();
        }
    }

    const incidencePercentage = (distanceAndDecice?.incidents / distanceAndDecice?.question_group_incidents).toFixed(2) * 100

    return (
        <div className=" flex flex-col grid-cols-1 md:grid-cols-3 gap-4 mt-2">
            <h5>{questionGroup?.name}</h5>
            {distance && distanceAndDecice && <span className=' capitalize '>{t("device")}: {distanceAndDecice.device}</span>}
            {distance && distanceAndDecice && <span>{t("distance")}: {distanceAndDecice.distance}</span>}
            {distance && distanceAndDecice && <span className=" font-bold " >{t("incidencePercentage")}: {incidencePercentage} %</span>}

            {
                answers.map((answer, i) => (
                    <div key={i}>
                        <div className='flex items-center mb-3 gap-2'>
                            <p className={`text-gray-700 dark:text-gray-200 font-semibold ${answer.question_type === 8 ? 'text-base' : 'text-xs'}`}>
                                {answer.name}
                            </p>

                        </div>
                        {answer.answers.length > 0 && answer.answers.map((r, i) => (
                            <div className='flex flex-col mb-2  gap-2' key={i}>
                                <div className='flex  items-center gap-2'>
                                    <span>{answer.question_type === DATE ? dateFormat(r.answers, 'DD-MM-YYYY') : ReactHtmlParser(r.answers)}</span>

                                    {r.incidents &&
                                        <>
                                            <Tag className="bg-emerald-100 text-emerald-600 dark:bg-emerald-500/20 dark:text-emerald-100 border-0 rounded text-xs p-1">
                                                {t('incidence')}
                                            </Tag>
                                        </>
                                    }
                                    {r.penality &&
                                        <Tag className="bg-red-100 text-red-600 dark:bg-red-500/20 dark:text-red-100 border-0 rounded text-xs p-1">
                                            {t('penalizable')}
                                        </Tag>
                                    }

                                    {r.incidents && operator.id === user.id &&
                                        <div className='flex flex-col items-center gap-2'>
                                            <HiOutlinePencilAlt size={20} onClick={() => handleShow(r.id)} />
                                        </div>
                                    }

                                </div>
                                {visible && idSelected === r.id &&
                                    <div className='w-[80%] flex flex-col items-end'>
                                        <Input
                                            value={commentData.text}
                                            onChange={(e) => handleComment(e, answer.name)}
                                        />
                                        <Button
                                            size="xs"
                                            variant="outline"
                                            onClick={onSubmit}
                                            loading={loading}
                                            className="mt-2 w-36"
                                        >
                                            {t("send_comment")}
                                        </Button>
                                    </div>
                                }
                            </div>
                        ))}

                        {answer.question_type === FILE &&
                            <div className='flex flex-wrap gap-2'>
                                {answer?.answers?.length > 0 &&
                                    answer?.answers.map(answer => (
                                        <Card
                                            className="w-[165px]"
                                            bodyClass="px-2 pt-2 pb-1"
                                            key={answer.original_name}
                                        >
                                            <FileItemCard className="w-[50px]" file={answer} />
                                            <div className="mt-1 flex justify-between items-center">
                                                <span className="text-xs">{(parseInt(answer.size) / 1024).toFixed(2)} KB</span>
                                                <div className="flex items-center">
                                                    <Tooltip title={t('download')}>
                                                        <a href={answer.file} download target="_blank">
                                                            <Button className="mr-1 rtl:ml-1" variant="plain" size="xs" icon={<HiOutlineDownload />} />
                                                        </a>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </Card>
                                    ))
                                }
                            </div>
                        }

                    </div>
                ))

            }
        </div>

    )
}

export default TicketAnswerNew