import ApiService from "./ApiService";

export async function apiGetUsers (params) {
    const { query } = params
    return ApiService.fetchData({
        url: query,
        method: 'get',
        //params
    })
}

export async function apiPostUser (data) {
    return ApiService.fetchData({
        url: `/users`,
        method: 'post',
       headers: { "Content-Type": "multipart/form-data" },
        data
    })
}

export async function apiPutUser (id, data) {
    return ApiService.fetchData({
        url: `/users/${id}`,
        method: 'post',
        headers: { "Content-Type": "multipart/form-data" },
        data
    })
}

export async function apiDeleteUser (id) {
    return ApiService.fetchData({
        url: `/users/${id}`,
        method: 'delete',
    })
}
export async function apiGetParameters () {
    return ApiService.fetchData({
        url: `/parameters`,
        method: 'get',
        //params
    })
}

export async function apiSendMailWithPassword (id){
    return ApiService.fetchData({
        url: `/users/${id}/reset_password`,
        method: 'post',
    })
}

export async function apiDisableUser (id){
    return ApiService.fetchData({
        url: `/users/${id}/disable`,
        method: 'post',
    })
}