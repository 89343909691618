export const inventoryStatusColor = {
	0: { label: 'Nuevo', dotClass: 'bg-emerald-500', textClass: 'text-emerald-500' },
	1: { label: 'Nuevo', dotClass: 'bg-emerald-500', textClass: 'text-emerald-500' },
	2: { label: 'En proceso', dotClass: 'bg-[#f7a511]', textClass: 'text-amber-500' },
	3: { label: 'Cancelado', dotClass: 'bg-red-500', textClass: 'text-red-500' },
	4: { label: 'Cerrado', dotClass: 'bg-red-500', textClass: 'text-red-500' },
	5: { label: 'Borrado', dotClass: 'bg-red-500', textClass: 'text-red-500' },
	6: { label: 'Cerrado por sistema', dotClass: 'bg-red-500', textClass: 'text-red-500' },
	7: { label: 'Reabierto', dotClass: 'bg-emerald-500', textClass: 'text-emerald-500' },
}

export const priorityStatusColor = {
	0: { label: 'Sin prioridad', dotClass: 'bg-zinc-500', textClass: 'text-zinc-500' },
	1: { label: 'Alta', dotClass: 'bg-red-500', textClass: 'text-red-500' },
	2: { label: 'Media', dotClass: 'bg-[#f7a511]', textClass: 'text-amber-500' },
	3: { label: 'Baja', dotClass: 'bg-emerald-500', textClass: 'text-emerald-500' },
}


export const deviceStatusColor = {
	0: { label: 'false', dotClass: 'bg-red-500', textClass: 'text-red-500' },
	1: { label: 'true', dotClass: 'bg-emerald-500', textClass: 'text-emerald-500' },
}


export const menuManageList = [
	{
		key: 'users',
		path: '/manage/users',
		title: 'Users',
		translateKey: 'nav_users',
		icon: 'users',
		authority: [],
		subMenu: []
	},
	{
		key: 'teams',
		path: '/manage/teams',
		title: 'Teams',
		translateKey: 'nav_teams',
		icon: 'teams',
		authority: [],
		subMenu: []
	},
	{
		key: 'channels',
		path: '/manage/channels',
		title: 'Channels',
		translateKey: 'nav_channels',
		icon: 'channels',
		authority: [],
		subMenu: []
	},
	{
		key: 'issue_type',
		path: '/manage/issuetype',
		title: 'Stores',
		translateKey: 'nav_issue_type',
		icon: 'issue_type',
		authority: [],
		subMenu: []
	},
	{
		key: 'stores',
		path: '/manage/stores',
		title: 'Stores',
		translateKey: 'nav_stores',
		icon: 'store',
		authority: [],
		subMenu: []
	},
	{
		key: 'devices',
		path: '/manage/devices',
		title: 'Devices',
		translateKey: 'nav_devices',
		icon: 'devices',
		authority: [],
		subMenu: []
	},
	{
		key: 'partners',
		path: '/manage/partners',
		title: 'Partners',
		translateKey: 'nav_partners',
		icon: 'store',
		authority: [],
		subMenu: []
	},

	{
		key: 'roles',
		path: '/manage/roles',
		title: 'Roles',
		translateKey: 'nav_roles',
		icon: 'home',
		authority: [],
		subMenu: []
	},


]